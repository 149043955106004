import { useRef, useState } from "react";
import Container from "../containers/Container";

import { Navigation, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/scrollbar";
import "swiper/css";
import type { Swiper as SwiperType } from "swiper";

import CategoryBox from "../containers/CategoryBox";
import { musicStyles } from "../../utils/StaticData";
import { useSearchParams } from "react-router-dom";
import PrevButton from "../PrevButton";
import NextButton from "../NextButton";

const Categories = () => {
  const [params] = useSearchParams();
  const category = params?.get("musicStyle");

  const swiperRef = useRef<SwiperType | null>(null);

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const updateNavigationState = (swiper: SwiperType) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const handleNext = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;
    const slidesPerView = swiper.params.slidesPerView as number;
    const nextIndex = Math.min(
      swiper.activeIndex + slidesPerView,
      swiper.slides.length - slidesPerView
    );
    swiper.slideTo(nextIndex);
  };

  const handlePrev = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;
    const slidesPerView = swiper.params.slidesPerView as number;
    const prevIndex = Math.max(swiper.activeIndex - slidesPerView, 0);
    swiper.slideTo(prevIndex);
  };

  return (
    <Container>
      <div className="flex  w-full items-center justify-center">
        <PrevButton disabled={isBeginning} onClick={handlePrev} size={20} />
        <div className="border-b-4 border-lilas w-3/4">
          <Swiper
            modules={[Navigation]}
            onSwiper={(swiper: SwiperType) => {
              swiperRef.current = swiper;
              updateNavigationState(swiper);
            }}
            onSlideChange={(swiper: SwiperType) =>
              updateNavigationState(swiper)
            }
            slidesPerView={3}
            breakpoints={{
              768: {
                slidesPerView: 6,
              },
              920: {
                slidesPerView: 9,
              },
            }}
            className="
                    pt-4
                    flex
                    flex-row
                    items-center
                    justify-between
                    overflow-x-auto
                "
          >
            {musicStyles.map((item) => (
              <SwiperSlide key={item.label}>
                <CategoryBox
                  key={item.label}
                  genero={item.label}
                  identifier={item.value}
                  png={item.png}
                  selected={category === item.label}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <NextButton disabled={isEnd} onClick={handleNext} size={20} />
      </div>
    </Container>
  );
};

export default Categories;
