import React from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import PrevButton from "../../PrevButton";
import NextButton from "../../NextButton";

interface IMenuPagination {
  onPreviousPage: () => void;
  onNextPage: () => void;
  currentPage: number;
  totalPages: number;
}

const MenuPagination: React.FC<IMenuPagination> = ({
  onNextPage,
  onPreviousPage,
  currentPage,
  totalPages,
}) => {
  return (
    <div className="flex gap-2 justify-between ">
      {totalPages > 1 && (
        <>
          <PrevButton disabled={currentPage <= 1} onClick={onPreviousPage} />
          <NextButton
            onClick={onNextPage}
            disabled={currentPage === totalPages}
          />
        </>
      )}
    </div>
  );
};

export default MenuPagination;
