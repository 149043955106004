import React, { useRef, useState } from "react";
import PortifolioSection from "../PortifolioSection";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css/scrollbar";
import "swiper/css";
import RoundPhotoBox from "../../containers/RoundPhotoBox";
import ItemCard from "../../../models/ItemCard";
import MenuPagination from "./MenuPagination";
import type { Swiper as SwiperType } from "swiper";

interface PortifolioItemCardProps {
  items: ItemCard[];
  title: string;
}

const PortifolioItemCard: React.FC<PortifolioItemCardProps> = ({
  items,
  title,
}) => {
  const swiperRef = useRef<SwiperType | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const updatePagination = (swiper: SwiperType) => {
    const slidesPerView = swiper.params.slidesPerView as number;
    const total = Math.ceil(swiper.slides.length / slidesPerView);
    if (!swiper.isBeginning && !swiper.isEnd) {
      setCurrentPage(2); // seta um valor valido para que ele possa percorrer
    } else {
      if (swiper.isBeginning) {
        setCurrentPage(1);
      }
      if (swiper.isEnd) {
        setCurrentPage(total);
      }
    }
    setTotalPages(total);
  };

  const body = (
    <div>
      <Swiper
        modules={[Navigation]}
        onSwiper={(swiper: SwiperType) => {
          swiperRef.current = swiper;
          updatePagination(swiper);
        }}
        onSlideChange={(swiper: SwiperType) => updatePagination(swiper)}
        slidesPerView={2}
        onResize={(swiper: SwiperType) => updatePagination(swiper)}
        breakpoints={{
          768: {
            slidesPerView: 3,
          },
          920: {
            slidesPerView: 4,
          },
        }}
        autoplay={{
          delay: 3000,
          stopOnLastSlide: false,
        }}
        className="
                    flex
                    flex-row
                    items-center
                    justify-between
                    overflow-x-auto
                "
      >
        {items?.map((item) => (
          <SwiperSlide key={item.name}>
            {item.name && (
              <RoundPhotoBox
                key={item.name}
                src={item.photo}
                title={item.name}
                subtitle={item.subName}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  const handleNextPage = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;
    const slidesPerView = swiper.params.slidesPerView as number;
    const nextIndex = Math.min(
      swiper.activeIndex + slidesPerView,
      swiper.slides.length - slidesPerView
    );
    swiper.slideTo(nextIndex);
  };

  const onPreviousPage = () => {
    const swiper = swiperRef.current;
    if (!swiper) return;
    const slidesPerView = swiper.params.slidesPerView as number;
    const prevIndex = Math.max(swiper.activeIndex - slidesPerView, 0);
    swiper.slideTo(prevIndex);
  };

  const swiper = swiperRef.current;

  const buttons = swiper && (
    <MenuPagination
      currentPage={currentPage}
      onNextPage={handleNextPage}
      onPreviousPage={onPreviousPage}
      totalPages={totalPages}
    />
  );

  return <PortifolioSection title={title} body={body} buttons={buttons} />;
};

export default PortifolioItemCard;
